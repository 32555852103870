<template>
  <div class="checkbox" :class="className">
    <input
      type="checkbox"
      :id="uniqueId"
      :checked="checked"
      :disabled="disabled"
      v-model="isAgree"
    />
    <label :for="uniqueId">
      <span class="checkbox__label">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import uniqueString from "unique-string";

export default {
  name: "Checkbox",
  data() {
    return {
      uniqueId: uniqueString(),
    };
  },
  props: {
    label: [String, Number],
    modelValue: [Boolean],
    disabled: { type: Boolean, default: false },
    checked: { type: Boolean, default: false },
  },
  computed: {
    isAgree: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        "checkbox--disabled": this.disabled,
      };
    },
  },
};
</script>

<style lang="scss">
.checkbox {
  display: block;
  margin-bottom: 11px;
  line-height: 1.7rem;
  &__label {
    font-size: 1.4rem;
    position: relative;
    top: 2px;
  }
  & input {
    height: initial;
    width: initial;
    display: none;
    cursor: pointer;
  }

  & label {
    position: relative;
    cursor: pointer;
  }

  & label:before {
    content: "";
    border: 1px solid var(--background--disabled);
    border-radius: 4px;
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    transition: all 0.2s ease-in-out;
  }

  & input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 4px;
    height: 8px;
    border: solid var(--white);
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
  & input:checked + label:before {
    background: var(--background--btn--red);
    border: 1px solid var(--background--btn--red);
  }
  & input:disabled + label:before {
    background: var(--background--disabled);
    border: 1px solid var(--background--disabled);
  }

  & input:checked:disabled + label:before {
    background: var(--background--disabled);
    border: 1px solid var(--background--disabled);
  }
  &--disabled {
    pointer-events: none;
  }
}
</style>
