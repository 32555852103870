<template>
  <div class="docs-upload" :class="className">
    <div class="docs-upload--left">
      <div class="docs-upload__icon" v-if="!disabled && tooltip">
        <Icon type="Help" :data-tooltip="tooltip" />
      </div>
      <p>
        {{ `${label}${isRequired ? "*" : ""}` }}
        <span v-if="fileChanged">(не сохранено)</span>
      </p>
    </div>
    <div class="docs-upload--right">
      <div class="docs-upload__file">
        <div
          v-if="Boolean(fileLoaded) || docs[keyName]"
          class="docs-upload__file-text"
        >
          <a v-if="!hashMedia" :href="fileLoaded" target="_blank">
            <p>
              Файл загружен
              <span v-if="type === 'portfolio'">
                {{
                  ` от ${moment(linkPortfolio?.created_at).format(
                    "DD.MM.YYYY"
                  )}`
                }}
              </span>
            </p>
          </a>
          <a v-else-if="linkShown(keyName)" @click="openImg">
            <p>
              Файл загружен
              <span v-if="type === 'portfolio'">
                {{
                  ` от ${moment(linkPortfolio?.created_at).format(
                    "DD.MM.YYYY"
                  )}`
                }}
              </span>
            </p>
          </a>

          <span v-else>Файл загружен </span>
          <Icon
            :type="fileChanged ? 'Warning' : 'ok'"
            v-if="!error"
            :data-tooltip="
              fileChanged && !fileLoaded ? undefined : 'Файл успешно загружен'
            "
          />
          <Icon
            class="docs-upload__icon-status"
            v-if="Boolean(fileLoaded) || docs[keyName]"
            :type="fileChanged ? 'Sync' : currentIcon"
            :style="
              fileChanged ? { color: '#595959' } : { color: currentIconColor }
            "
            :data-tooltip="
              fileChanged ? 'Документ ожидает проверки' : currentIconTooltip
            "
          />
        </div>
        <div v-else>Файл не выбран</div>
        <Icon
          v-if="infoText"
          type="Help"
          :data-tooltip="infoText"
          class="docs-upload__tooltip"
        />
      </div>
      <div class="file" :class="className">
        <input
          :id="uniqueId"
          type="file"
          name="file"
          ref="file"
          @change="handleFileChange($event)"
          class="file__input"
        />
        <label :for="uniqueId" class="file__label">
          <div class="btn btn--small docs-upload__btn">
            {{ name }}
          </div>
        </label>
        <div class="file__preloader">
          <div class="file__anim"></div>
        </div>
      </div>
      <div class="docs-upload__info">
        *файлы с разрешением pdf не более {{ sizeFile }} Мб
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import uniqueString from "unique-string";
import Icon from "@/components/Blocks/Icon";
import { getPDF } from "@/utils/helpers";
import moment from "moment";

export default {
  name: "PDFInputFile",
  components: { Icon },
  data() {
    return {
      uniqueId: uniqueString(),
      expectedFormats: ["application/pdf"],
      invalidChars: ["*", "/", ":", "<", ">", "?", "\\", "|", "\\"],
      imageValidationMessages: [
        "Размер файла слишком велик",
        "Неверный формат файла",
        "Название файла не должно содержать символы: */:<>?\\|\\",
      ],
      img_pdf: "",
      fileChanged: false,
    };
  },
  props: {
    hasFile: Boolean,
    label: [String, Number],
    name: [String, Number],
    fileLoaded: [Boolean, String],
    error: Boolean,
    keyName: String,
    tooltip: String,
    infoText: String,
    isRequired: Boolean,
    sizeFile: {
      type: Number,
      default: 10,
    },
    type: {
      type: String,
      default: "education",
    },
    itemType: {
      type: String,
      default: "education",
    },
    linkPortfolio: [String, Object],
    disabled: Boolean,
  },
  watch: {
    docs: {
      handler: function () {
        this.img_pdf = this.docs[`${this.keyName}`];
      },
      deep: true,
    },
    img_pdf(value) {
      if (this.type === "portfolio") return;
      this.fileChanged = Boolean(value);
    },
    // link() {
    //   this.fileChanged = false;
    // },
  },
  methods: {
    async openImg() {
      await getPDF(this.link, this.label);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!this.imageValidator(file)) return;
      const reader = new FileReader();
      reader.onload = ((aImg) => {
        return function (e) {
          aImg.src = e.target.result;
        };
      })(this.$refs.file);
      reader.readAsDataURL(file);
      this.$emit("file-change", file);
      if (this.type !== "portfolio") {
        this.$store.commit("pushToTray", {
          text: "Данные успешно сохранены",
          type: "success",
        });
      }
    },
    imageValidator(file) {
      const maximumSizeBytes = 1048576 * this.sizeFile;

      if (!this.expectedFormats.includes(file?.type)) {
        this.$store.commit("pushToTray", {
          text: this.imageValidationMessages[1],
          type: "error",
        });
        return false;
      } else if (file.size > maximumSizeBytes) {
        this.$store.commit("pushToTray", {
          text: this.imageValidationMessages[0],
          type: "error",
        });
        return false;
      } else if (this.stringValidator(file.name)) {
        this.$store.commit("pushToTray", {
          text: this.imageValidationMessages[2],
          type: "error",
        });
        return false;
      } else return true;
    },
    stringValidator(str) {
      let arr;
      arr = this.invalidChars.filter((item) => {
        if (str.includes(item)) return true;
      });
      return arr.length > 0;
    },
    linkShown(value) {
      if (this.user[this.itemType]?.document_files) {
        return this.user[this.itemType]?.document_files[value]?.full;
      } else return this.linkPortfolio?.url_original;
    },
    moment,
  },
  computed: {
    link() {
      return this.itemType === "education" ||
        this.itemType === "social_documents"
        ? this.user[this.itemType]?.document_files[this.keyName]?.full
        : this.linkPortfolio?.url_original;
    },
    statusDocument() {
      let status = {};
      if (
        (this.user[this.itemType]?.document_files[this.keyName].full ||
          this.docs[this.keyName]) &&
        this.user.info_validation_documents?.data.length > 0
      ) {
        if (
          this.user.info_validation_documents?.data?.find((item) => {
            return item.key_document === this.keyName;
          })
        ) {
          status = this.user.info_validation_documents?.data?.find((item) => {
            return item.key_document === this.keyName;
          });
        }
      }
      return status;
    },
    currentIcon() {
      let icon = "Sync";
      if (this.statusDocument.status === 40) {
        icon = "Cancel";
      }
      if (this.statusDocument.status === 50) {
        icon = "Confirm-Circle";
      }
      return icon;
    },
    currentIconColor() {
      let color = "#595959";
      if (this.statusDocument.status === 40) {
        color = "red";
      }
      if (this.statusDocument.status === 50) {
        color = "green";
      }
      return color;
    },
    currentIconTooltip() {
      return (
        this.docsStatus[this.statusDocument.status] ??
        "Документ ожидает проверки"
      );
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        "docs-upload--has-file": this.hasFile,
        "docs-upload--loaded": this.fileLoaded,
        "docs-upload--error": this.error,
        "docs-upload--new": this.fileChanged,
      };
    },
    ...mapState(["docs"]),
    ...mapState("user", ["user"]),
    ...mapGetters(["hashMedia", "docsStatus"]),
  },
};
</script>

<style lang="scss">
.docs-upload {
  &--disabled {
    label {
      div {
        pointer-events: none;
        background: var(--background--btn--secondary--disabled);
        border: 1px solid var(--background--disabled);
      }
    }
  }
}
</style>
